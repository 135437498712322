import React from 'react';
import PropTypes from 'prop-types';

import Layout from './layout';

const ProjectLayout = ({ children }) => (
  <Layout>
    <main className="project-page">{children}</main>
  </Layout>
);

ProjectLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProjectLayout;
