/* eslint-disable no-restricted-syntax */
import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Img from 'gatsby-image';
import { Fade } from 'react-reveal';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';

import ProjectLayout from '../components/project-layout';
import SEO from '../components/seo';
import MobileNav from '../components/mobile-nav';
import Header from '../components/header';
import ContactForm from '../components/Forms/ContactForm';
import 'pure-react-carousel/dist/react-carousel.es.css';
import right from '../assets/icons/right-chevron.svg';
import left from '../assets/icons/left-chevron.svg';

const ProjectPageTemplate = props => {
  const post = get(props, 'data.contentfulProject');
  const postID = post.id;
  const ProjectEdges = get(props, 'data.allContentfulProject.edges');
  const sizes = `1440px, 960px`;
  const sizesSmall = `940px, 670px`;
  const offsetImg1 = `360px, 550px`;
  const offsetImg2 = `550px, 395px`;
  let images;
  let hasImages = false;
  const categoriesArr = post.category;
  const categories = categoriesArr
    .reduce((a, b) => {
      return a.concat(b).concat(', ');
    }, [])
    .slice(0, -1);

  const categoryTitle = () => {
    if (categoriesArr.length === 1) {
      return 'Category';
    }

    return 'Categories';
  };

  if (post.projectImages !== null) {
    images = post.projectImages[0].image;
    hasImages = true;
  }

  // eslint-disable-next-line no-shadow
  const currentProjectEdge = postID => {
    const postArr = ProjectEdges.filter(project => project.node.id === postID);

    if (!postArr.length) {
      return null;
    }

    return postArr[0];
  };

  const projectEdge = currentProjectEdge(postID);

  const renderImage = image => {
    if (image) {
      return (
        <div>
          <Img
            className="slider-img"
            fluid={{ ...image.fluid, sizesSmall }}
            alt={image.title}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <ProjectLayout>
      <SEO
        title={post.title}
        description={post.description ? post.description.description : ''}
      />
      <Header headerType="" />
      <div id="outer-container">
        <MobileNav />
      </div>
      <div id="layout-container">
        <article className="project-content">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumbs">
                  <ul>
                    <li>
                      <AniLink fade className="home" to="/">
                        Home
                        <span>/</span>
                      </AniLink>
                    </li>
                    <li>
                      <AniLink fade className="current" to="/projects">
                        Projects
                      </AniLink>
                    </li>
                  </ul>
                </div>
                <div className="project-details">
                  <h1>{post.title}</h1>
                  {categoriesArr && (
                    <div className="project-categories">
                      <p className="bold m0">
                        {categoryTitle()}
                        <span style={{ marginRight: '6px' }} />
                        &#8211;
                        <span style={{ marginRight: '6px' }} />
                        {categories}
                      </p>
                    </div>
                  )}
                  {post.yearCompleted && (
                    <p className="bold mb1">
                      Completed &#8211;
                      <span style={{ marginRight: '6px' }} />
                      {post.yearCompleted}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Img
            className="featured-img"
            fluid={{ ...post.featuredImage.fluid, sizes }}
            alt={post.featuredImage.title}
          />
          <div className="container sml-container">
            <div className="row">
              <div className="col-12">
                {post.description && (
                  <Fade>
                    <div className="content">
                      <p>{post.description.description}</p>
                    </div>
                  </Fade>
                )}
                {hasImages && (
                  <Fade>
                    <>
                      <div className="mobile-slider">
                        <div className="img-carousel">
                          <CarouselProvider
                            naturalSlideWidth={100}
                            naturalSlideHeight={125}
                            totalSlides={images.length}
                            dragEnabled={false}
                            touchEnabled={false}
                          >
                            <Slider className="slider-element">
                              {/* eslint-disable-next-line */}
                              {images.map(({ image }, i) => (
                                <Slide index={i}>
                                  {renderImage(images[i])}
                                </Slide>
                              ))}
                            </Slider>
                            <div className="indicators">
                              <ButtonBack className="prev">
                                <img
                                  src={left}
                                  alt="Previous slide"
                                  width="10px"
                                />
                              </ButtonBack>
                              <ButtonNext className="next">
                                <img
                                  src={right}
                                  alt="Next slide"
                                  width="10px"
                                />
                              </ButtonNext>
                            </div>
                          </CarouselProvider>
                        </div>
                      </div>
                    </>
                  </Fade>
                )}
                {images[0] ? (
                  <Fade>
                    <div className="first-img lg">
                      {images[0] && (
                        <Img
                          className="content-img"
                          fluid={{ ...images[0].fluid, sizesSmall }}
                          alt={images[0].title}
                        />
                      )}
                    </div>
                  </Fade>
                ) : null}
              </div>
              {images[1] && (
                <Fade>
                  <div className="col-12 offset-holder">
                    <div className="offset-img">
                      {images[1] ? (
                        <Img
                          className="content-img first-offset"
                          fluid={{ ...images[1].fluid, offsetImg1 }}
                          alt={images[1].title}
                        />
                      ) : null}
                      {images[2] ? (
                        <Img
                          className="content-img second-offset"
                          fluid={{ ...images[2].fluid, offsetImg2 }}
                          alt={images[2].title}
                        />
                      ) : null}
                    </div>
                  </div>
                </Fade>
              )}
              {images[3] ? (
                <Fade>
                  <div className="col-12 standard-holder">
                    <div className="fourth-img lg">
                      {images[3] && (
                        <Img
                          className="content-img"
                          fluid={{ ...images[3].fluid, sizesSmall }}
                          alt={images[3].title}
                        />
                      )}
                    </div>
                  </div>
                </Fade>
              ) : null}
              {images[4] && (
                <Fade>
                  <>
                    <div className="col-12 multi-img">
                      <div className="collage">
                        {images[4] ? (
                          <Img
                            className="first-img content-img"
                            fluid={{ ...images[4].fluid, offsetImg2 }}
                            alt={images[4].title}
                          />
                        ) : null}
                        {images[5] ? (
                          <Img
                            className="second-img content-img"
                            fluid={{ ...images[5].fluid, offsetImg2 }}
                            alt={images[5].title}
                          />
                        ) : null}
                        {images[6] ? (
                          <Img
                            className="third-img content-img"
                            fluid={{ ...images[6].fluid, offsetImg1 }}
                            alt={images[6].title}
                          />
                        ) : null}
                      </div>
                    </div>
                  </>
                </Fade>
              )}
            </div>
          </div>
        </article>
        <Fade>
          <div className="next-project-block">
            {projectEdge.previous && (
              <div
                className={`prev ${projectEdge.next ? 'normal' : 'full-width'}`}
              >
                <AniLink
                  fade
                  className="link"
                  to={`/projects/${projectEdge.previous.slug}`}
                >
                  <span className="title">Previous</span>
                  <span className="project-name">
                    {projectEdge.previous.title}
                  </span>
                  <img
                    className="featured-img"
                    src={projectEdge.previous.featuredImage.fluid.src}
                    alt={projectEdge.previous.featuredImage.title}
                  />
                  <div className="overlay" />
                </AniLink>
              </div>
            )}
            {projectEdge.next && (
              <div
                className={`next ${
                  projectEdge.previous ? 'normal' : 'full-width'
                }`}
              >
                <AniLink
                  fade
                  className="link"
                  to={`/projects/${projectEdge.next.slug}`}
                >
                  <span className="title">Next</span>
                  <span className="project-name">
                    {projectEdge && projectEdge.next.title}
                  </span>
                  <img
                    className="featured-img"
                    src={projectEdge.next.featuredImage.fluid.src}
                    alt={projectEdge.next.featuredImage.title}
                  />
                  <div className="overlay" />
                </AniLink>
              </div>
            )}
          </div>
        </Fade>
        <ContactForm
          heading="Start with a free quote!"
          subheading="One our team will be in contact to help you with your request. Obligation free."
          theme="grey"
          formType="project"
        />
      </div>
    </ProjectLayout>
  );
};

export default ProjectPageTemplate;

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      id
      title
      slug
      linkUrl
      category
      yearCompleted
      featuredImage {
        title
        fluid(maxWidth: 1440, maxHeight: 960) {
          ...GatsbyContentfulFluid_withWebp
        }
        file {
          url
        }
      }
      projectImages {
        image {
          title
          fluid(maxWidth: 940, maxHeight: 670) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    allContentfulProject {
      edges {
        next {
          id
          title
          slug
          featuredImage {
            title
            fluid(maxWidth: 940, maxHeight: 670) {
              ...GatsbyContentfulFluid_withWebp
            }
            file {
              url
            }
          }
        }
        previous {
          id
          title
          slug
          featuredImage {
            title
            fluid(maxWidth: 940, maxHeight: 670) {
              ...GatsbyContentfulFluid_withWebp
            }
            file {
              url
            }
          }
        }
        node {
          id
          title
          slug
          linkUrl
          category
          featuredImage {
            title
            fluid(maxWidth: 452, maxHeight: 325) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          projectImages {
            image {
              id
              title
              file {
                url
                fileName
                contentType
              }
            }
          }
        }
      }
    }
  }
`;
